/* function resetMainHeight(){
    var headerHeight = $('.cntr_header').outerHeight();
    var footerHeight = $('footer').outerHeight();
    var mainHeight = $('main').outerHeight();
    var screenwidth = $(window).outerWidth();
    var screenheight = $(window).outerHeight();
    $(function() {
        if (screenwidth >= 1024) {
            $('main').css('height', screenheight - headerHeight - footerHeight - '56');
            $('main').css('min-height', mainHeight);
        }
        else{
            $('main').css('height', 'inherit');
            $('main').css('min-height', 'inherit');
        }
    });
}
 */


$(document).ready(function () {
    //resetMainHeight()

    // FOCUS OP EERSTE INPUT FIELD
    $('#modalInloggen').on('shown.bs.modal', function () {
        $('#inputEmail').trigger('focus')
    })
    $('#modalRegistreren').on('shown.bs.modal', function () {
        $('#inputNaamOnderneming').trigger('focus')
    })
    // ANCHOR SMOOTH ANIMATION
    $(document).on('click', 'a.anchor', function(event){     
        event.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top-140}, 900);
    });
})

$(window).on("load", function(){
});

$(window).resize(function(){
	//resetMainHeight();
});




    // jQyeryDate by https://jsfiddle.net/manattweb/meh97ubz/113/
    //Bind keyup/keydown to the input
    $('.ondernemingsnummer').bind('keyup', 'keydown', function (e) {
        //check for numerics
        var thisValue = $(this).val();
        thisValue = thisValue.replace(/[^0-9\//]/g, '');
        //get new value without letters
        $(this).val(thisValue);
        thisValue = $(this).val();
        var numChars = thisValue.length;
        $('#keyCount').html(numChars);
        var thisLen = thisValue.length - 1;
        var thisCharCode = thisValue.charCodeAt(thisLen);
        $('#keyP').html(thisCharCode);
        //To accomdate for backspacing, we detect which key was pressed - if backspace, do nothing:
        if (e.which !== 10) {
            if (numChars === 4) {
                if (thisCharCode == 47) {
                    var thisV = '0' + thisValue;
                    $(this).val(thisV);
                } else {
                    thisValue += '/';
                    $(this).val(thisValue);
                }
            }
            if (numChars === 8) {
                if (thisCharCode == 47) {
                    var a = thisValue;
                    var position = 3;
                    var output = [a.slice(0, position), '0', a.slice(position)].join('');
                    $(this).val(output);
                } else {
                    thisValue += '/';
                    $(this).val(thisValue);
                }
            }
            if (numChars > 12) {
                var output2 = thisValue.slice(0, 12);
                $(this).val(output2);
            }
        }
    });
    $('.ondernemingsnummer').blur(function () {
        var thisValue = $(this).val();
        var numChars = thisValue.length;
        if (numChars < 12) {
            $(this).addClass('brdErr');
            $('#dateErr1').slideDown('fast');
            $(this).select();
        } else {
            $(this).removeClass('brdErr');
            $('#dateErr1').hide();
        }
    });