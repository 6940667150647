
$(document).ready(function() {	
 
// 1. . ### MOBILE NAV INVULLEN ###########################################
	//vars
//	var langnav =$('.row_navlanguage').html();
//	var minornav =$('.row_navminor').html();
	var majornav =$('.topnav').html();
	
	// LANGNAV copy en paste + opkuis
/*	
	$('.mobileNavPanel .langnav').append(langnav);
	$('.mobileNavPanel .langnav ul').attr( 'class', '' );
	//do not clean up the li-classes: you'll need the 'active' state
	//$('.mobileNavPanel .langnav ul li').attr( 'class', '' )
	$('.mobileNavPanel .langnav ul div').attr( 'class', '' );
	//remove divs around child ul's
	$('.mobileNavPanel .langnav ul div').contents().unwrap();
*/
	
	// MINORNAV copy en paste + opkuis
/*	
	$('.mobileNavPanel .minornav').append(minornav);
	$('.mobileNavPanel .minornav ul').attr( 'class', '' );
	//do not clean up the li-classes: you'll need the 'active' state
	//$('.mobileNavPanel .minornav ul li').attr( 'class', '' )
	$('.mobileNavPanel .minornav ul div').attr( 'class', '' );
	//remove divs around child ul's
	$('.mobileNavPanel .minornav ul div').contents().unwrap();	
*/
	
	//NAVTOP
	$('.mobileNavPanel .majornav').append(majornav);
	$('.mobileNavPanel .majornav ul').attr( 'class', '' ) // eerst wordt de class weggedaan voor simultaan alles kunnenlaten openklappen = beter voor UX anders verspringt menu teveel op en neer
	$('.mobileNavPanel .majornav ul').addClass( 'sf-mega' ) // moet er terug op gezet worden om CSS-styling te kunnen houden  
	//do not clean up the li-classes: you'll need the 'active' state
	//$('.mobileNavPanel .majornav ul li').attr( 'class', '' )
	$('.mobileNavPanel .majornav ul div').attr( 'class', '' )
	//remove divs around child ul's
	$('.mobileNavPanel .majornav ul div').contents().unwrap();
	
	
	

// 2. MAJORNAV --- MOBILE NAV CLASSES TOEVOEGEN EN UITKLAPPERS DEFINIEREN ###########################################
	// check of een li een child ul heeft.
	// class toevoegen (css zet dan een uitklapsymbool aan de rechterkant)
	var checkchildul= function(){
		$('.mobileNavPanel .majornav ul > li').each(function(){
			// var listitem = $(this);
			if($(this).has("ul").length){
				//alert($(this))
				$(this).addClass('hasChildren');
				//no children on 'this', try find instead
				$(this).find(">:first-child").after('<span class="uitklap"></span>')
				$(".hasChildren > a").click(function (e){
					e.preventDefault();	
				});
			}
		})
	}
	checkchildul()
	
	var majornavclick = function(){
		$('.mobileNavPanel .majornav .hasChildren .uitklap').click(function(event) {
//			event.preventDefault();
			$(this).next().toggle(400);
		})
	}
	majornavclick();
	
	
	
	
			
// 3. ### SIDENAV CLASSES TOEVOEGEN EN UITKLAPPERS DEFINIEREN ###########################################
	// check of een li een child ul heeft.
	// class toevoegen (css zet dan een uitklapsymbool aan de rechterkant)
/*	var checksidenavchildul= function(){
		$('.sidenav ul > li').each(function(){
			// var listitem = $(this);
			if($(this).has("ul").length){
				//alert($(this))
				$(this).addClass('hasChildren');
				//no children on 'this', try find instead
				$(this).find(">:first-child").after('<span class="uitklap"><i class="fa fa-angle-down"></i></span>')
				$(".hasChildren > a").click(function (e){
					e.preventDefault();	
				});
			}
		})
	}
	checksidenavchildul()

	var sidenavclick = function(){
		$('.sidenav .hasChildren .uitklap').click(function(event) {
			$(this).next().toggle(200);
			$(this).find("i.fa").toggleClass( "fa-angle-down fa-angle-up");
			$(this).parents().siblings('.hasChildren').children('.subnav').hide(400);
		})
	}
	sidenavclick();
	*/
	
			
// 4. ### MINORAV CLASSES TOEVOEGEN EN UITKLAPPERS DEFINIEREN ###########################################
/*	
	var checkminornavchildul= function(){
			$('.mobileNavPanel .minornav ul > li').each(function(){
				// var listitem = $(this);
				if($(this).has("ul").length){
					//alert($(this))
					$(this).addClass('hasChildren');
					//no children on 'this', try find instead
					$(this).find(">:first-child").after('<span class="uitklap"><i class="fa fa-angle-down"></i></span>')
					$(".hasChildren > a").click(function (e){
						e.preventDefault();	
					});
				}
			})
		}	
		checkminornavchildul()

		var minornavclick = function(){
			$('.mobileNavPanel .minornav .hasChildren .uitklap').click(function(event) {
				event.preventDefault();
				$(this).next().toggle(400);
				//$(this).parent().siblings().children('ul').hide(400);
			})
		}
		 minornavclick();
*/
	
			

// 5. ### LANGAV CLASSES TOEVOEGEN EN UITKLAPPERS DEFINIEREN ###########################################
/*
		var checklangnavchildul= function(){
			$('.mobileNavPanel .langnav ul > li').each(function(){
				// var listitem = $(this);
				if($(this).has("ul").length){
					//alert($(this))
					$(this).addClass('hasChildren');
					//no children on 'this', try find instead
					$(this).find(">:first-child").after('<span class="uitklap"><i class="fa fa-angle-down"></i></span>')
					$(".hasChildren > a").click(function (e){
						e.preventDefault();	
					});
				}
			})
		}	
		checklangnavchildul()

		var langnavclick = function(){
			$('.mobileNavPanel .langnav .hasChildren .uitklap').click(function(event) {
				event.preventDefault();
				$(this).next().toggle(400);
				//$(this).parent().siblings().children('ul').hide(400);
			})
		}
		 langnavclick();
*/
	

})//doc.ready